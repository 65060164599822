.modal__pop {
    background: #fff;
    border: 2px solid #aaa;
    border-radius: 10px;
    z-index: 999;
    max-width: 870px;
    min-width: 250px;
    margin: auto;
    padding: 40px 90px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: #000;
    opacity: 0.75;
}

.input__number input {
    font-family: monospace;
    border-radius: 6px;
    border: 1px solid lightgrey;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
    margin: 4px;
    padding-left: 7px;
    width: 35px;
    height: 45px;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    background-color: #FBFBFB;
}
.input__number:focus {
    border: 1px solid #772AF3;
}
.modal__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 10px;

    color: #000000;
}
.modal__desc {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    font-weight: 300;
    color: #9c9c9c;
    max-width: 292px;
    margin: 0 auto;

    margin-bottom: 20px;
}
.img_logo {
    background-image: url('../../assets/done.svg');
    width: 110px;
    height: 110px;
    margin: 0 auto;
}
.closeIcon {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 22px;
    right: 22px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    background-image: url('../../assets/closeIcon.svg');
    background-repeat: no-repeat;
}
@media (max-width: 500px) {
    .modal__pop {
        padding: 40px 50px;
    }
    .vsegdada {
        width: 240px !important;
    }
    .tinkoff {
        width: 240px !important;
    }
}
@media (max-width: 330px) {
    .modal__pop {
        padding: 40px 25px;
    }
}
