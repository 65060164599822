.text__approved {
    font-size: 24px;
    background-color: #e1e2e3;
    padding: 9px 12px;
    margin-top: 15px;
    text-align: center;
    border-radius: 4px;
}
.text__rejected {
    font-size: 24px;
    /* background-color: #e1e2e3; */
    padding: 9px 12px;
    margin-top: 15px;
    text-align: center;
    border-radius: 4px;
}