.modal__pop {
  background: #fff;
  border-radius: 10px;
  z-index: 999;
  margin: auto;
  padding: 40px 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: 0.75;
}
.modal__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 10px;

  color: #000000;
}
.modal__desc {
  max-width: 430px;
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.img_logo {
  background-image: url('../../assets/done_icon.png');
  width: 110px;
  height: 110px;
  margin: 0 auto;
}
.closeIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 22px;
  right: 22px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  background-image: url('../../assets/closeIcon.svg');
  background-repeat: no-repeat;
}
@media (min-width: 900px) {
  .modal__pop {
    min-width: 670px;
  }
}
@media (min-width: 800px) {
  .modal__pop {
    min-width: 620px;
  }
}
@media (max-width: 500px) {
  .modal__pop {
    padding: 40px 0px;
    min-width: 370px;
  }
  .vsegdada {
    width: 240px !important;
  }
  .tinkoff {
    width: 240px !important;
  }
}
@media (max-width: 330px) {
  .modal__pop {
    padding: 40px 25px;
  }
}
