.header {
  width: 100%;
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
}

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 24px 0px;
}
.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
}
.rowSlider {
  margin-top: 28px;
  margin-left: 100px;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
}
.rowSlider::-webkit-scrollbar {
  display: none;
}
.iconButton {
  display: block;
}
.button__history span {
  margin-top: -20px;
  display: block;
  margin-left: 35px;
}

.btn:hover {
  background-color: #bbbdbf;
  transition: 0.2s;
}

.btn:focus {
  background-color: #772af3;
  transition: 0.2s;
}
.btnCurrent {
  background-color: #772af3 !important;
  color: #fff !important;
}
@media (max-width: 790px) {
  .button__history span {
    display: none;
  }
  .iconButton {
    padding-right: 0px;
    width: 27px;
    height: 20px;
  }
  .rowSlider {
    margin-left: 10px;
  }
}
