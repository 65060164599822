.modal__pop {
  background: #fff;
  border-radius: 10px;
  z-index: 999;
  min-width: 250px;
  margin: auto;
  padding: 40px 90px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000;
  opacity: 0.75;
}

.modal__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 10px;

  color: #000000;
}

.modal__desc {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  font-weight: 300;
  color: #9c9c9c;
  max-width: 292px;
  margin: 0 auto;

  margin-bottom: 20px;
}

.buttons__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tinkoff {
  background-color: #ffde01 !important;
  border-radius: 30px !important;
  padding: 14px 18px !important;
  border: none !important;
  width: 292px !important;
  cursor: pointer;
  color: #000 !important;
  min-width: 200px;
}

.tinkoff:disabled {
  background-color: #efefef !important;
}

.vsegdada:disabled {
  background-color: #efefef !important;
  color: #000 !important;
}

.vsegdada {
  margin-top: 10px !important;
  background-color: #4e2f8c !important;
  border-radius: 30px !important;
  padding: 14px 18px !important;
  width: 292px !important;
  border: none !important;
  cursor: pointer;
  color: #fff !important;
  min-width: 200px;
}

.sberbank {
  margin-bottom: 10px !important;
  background-color: #158f2c !important;
  border-radius: 30px !important;
  padding: 14px 18px !important;
  width: 292px !important;
  border: none !important;
  cursor: pointer;
  color: #fff !important;
  min-width: 200px;
}

.sbp {
  background-color: #F7F1E5 !important;
  border-radius: 30px !important;
  padding: 14px 18px !important;
  width: 292px !important;
  border: none !important;
  cursor: pointer;
  color: #000 !important;
  min-width: 200px;
  text-transform: none !important;
}

.sbp_logo {
  background-image: url('../../assets/sbp-logo.svg');
  width: 18px;
  height: 22px;
}

.img_logo {
  background-image: url('../../assets/money.svg');
  width: 110px;
  height: 110px;
  margin: 0 auto;
}

.closeIcon {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 22px;
  right: 22px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  background-image: url('../../assets/closeIcon.svg');
}

@media (max-width: 800px) {
  .modal__pop {
    padding: 40px 50px;
  }
}

@media (max-width: 500px) {
  .modal__pop {
    width: auto;
    padding: 40px 30px;
  }

  .vsegdada {
    width: 260px !important;
  }

  .tinkoff,
  .sberbank,
  .sbp {
    width: 260px !important;
  }
}