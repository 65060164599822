@keyframes line-scale-pulse-out {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1);
  }
}

.image-slides-loading > div {
  display: inline-block;
  width: 4px;
  height: 35px;
  margin: 2px;
  border-radius: 2px;
  background-color: #fff;
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation-fill-mode: both;

  &:nth-child(2),
  &:nth-child(4) {
    animation-delay: -0.4s !important;
  }

  &:nth-child(1),
  &:nth-child(5) {
    animation-delay: -0.2s !important;
  }
}

.image-slides-view-port {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000;
  touch-action: none;
}

.image-slides-container {
  display: inline-block;
  position: relative;
  overflow: visible;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  will-change: transform;
  touch-action: none;
}

.image-slides-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  padding-top: 6px;
  border: 0;
  border-radius: 2px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.image-slides-blackboard {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-right: 10px;
  text-align: center;
  vertical-align: middle;
}

.image-slides-content {
  will-change: transform;
  user-select: none;
}

.image-slides-page-button {
  position: absolute;
  top: 50%;
  z-index: 100;
  width: 32px;
  height: 75px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: rgba(100, 100, 100, 0.2);
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image-slides-prev {
  left: 0;
}

.image-slides-next {
  right: 0;
}
