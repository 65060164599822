.desctop__count {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-right: 35px;
}
.mobile__count {
  display: none;
}

@media (max-width: 780px) {
  .desctop__count {
    display: none;
  }
  .mobile__count {
    display: flex;
    margin-top: 10px;
  }
  .mobile {
    justify-content: space-between;
  }
}
