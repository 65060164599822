.close__block {
  z-index: 10000000;
  position: fixed;
  right: 40;
  top: 40;
  cursor: pointer;
}
.close__block > img {
  width: 100px;
  height: 100px;
}

@media (max-width: 790px) {
  .carousel {
    display: flex;
    width: 100%;
    grid-gap: 5px;
  }
  .carousel > div {
    width: 50px;
    height: 53px;
  }
  .close__block {
    z-index: 10000000;
    position: fixed;
    right: 10;
    top: 15;
    cursor: pointer;
  }
  .close__block > img {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 330px) {
  .carousel > div {
    width: 40px;
    height: 43px;
  }
}
